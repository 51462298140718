import type { TrackingEvent, TrackingStrategy } from 'bb/tracker/types';
import { isBrowser } from 'bb/utils/environment';

// --- Utils ---

const mapToUAEvent = (category: string, action: string, label: string) => ({
    event: 'GAEvent', // GTM event
    eventCategory: category,
    eventAction: action,
    eventLabel: label
});

const push = (payload: Record<string, unknown>): number | void =>
    window?.dataLayer?.push(payload);

// Generic UA event mapping for tracking events using plain category,action,label (like many signup events)
const createUAEvent = ({ category = '', action = '', label = '' }) =>
    mapToUAEvent(category, action, label);

// --- Navigation Events ---

const createVirtualPageviewEvent = ({ url = '', routeName = '' }) => ({
    event: 'VirtualPageview', // GTM event
    virtualPageURL: url,
    virtualPageName: routeName
});

// --- CTA Events ---

const createSignupCtaEvent = ({ name = '' }) =>
    mapToUAEvent('go to signup', 'cta click', name);

const createWinbackCtaEvent = ({ name = '' }) =>
    mapToUAEvent('winback', 'cta click', name);

const createAccountCtaEvent = ({ name = '' }) =>
    mapToUAEvent('go to account', 'cta click', name);

// --- Account & Subscription Events ---

const createCancelSubscriptionEvent = ({ action = '', label = '' }) =>
    mapToUAEvent('Cancel Account', action, label);

const createAccountEvent = ({ action = '', product = '' }) =>
    mapToUAEvent('account', action, product);

const createFamilyAccountEvent = ({ action = '' }) =>
    mapToUAEvent('familyaccount', 'profile-settings', action);

const createSubscriptionEvent = ({
    action = '',
    toProduct = '',
    fromProduct = ''
}) => mapToUAEvent(action, toProduct, fromProduct);

// --- Registration (Signup) Events (note that many signup events map directly to createUAEvent) ---

const createActivationCompleteEvent = ({ category = '', email = '' }) =>
    // Create normal UA event, but also add email property for some tracking in GTM
    ({ ...mapToUAEvent(category, 'complete', ''), email });

const createTrialRefusedEvent = ({ category = '' }) =>
    mapToUAEvent(category, 'trial refused', '');

const createGeoBlockedEvent = ({ category = '' }) =>
    mapToUAEvent(category, 'geo blocked', '');

// --- Share Event ---

const createShareEvent = ({ action = '', status = '' }) =>
    mapToUAEvent('share', action, status);

// --- Giftcard Events ---

const createBuyGiftcardQuantityEvent = ({ action = '', quantity = '' }) =>
    mapToUAEvent('giftcard', action, quantity);

const createBuyGiftcardEvent = ({ action = '', type = '' }) =>
    mapToUAEvent('giftcard', action, type);

const createRedeemExistingCustomerEvent = ({ name = '', type = '' }) =>
    mapToUAEvent('giftcard', 'redeem - existing customer', `${name}-${type}`);

const createRedeemCampaignCodeEvent = () =>
    mapToUAEvent(
        'giftcard',
        'redeem campaign code on redeem gift card page',
        ''
    );

// --- Strategy ---

type EventCreator = (data: Record<string, unknown>) => Record<string, unknown>;
type EventMap = Record<string, EventCreator>;

const UAEventMap: EventMap = {
    navigation: createVirtualPageviewEvent,
    signupCta: createSignupCtaEvent,
    winbackCta: createWinbackCtaEvent,
    accountCta: createAccountCtaEvent,
    subscription: createSubscriptionEvent,
    cancelSubscription: createCancelSubscriptionEvent,
    account: createAccountEvent,
    familyaccount: createFamilyAccountEvent,
    lead: createUAEvent,
    downloadApp: createUAEvent,
    userDetails: createUAEvent,
    paymentDetails: createUAEvent,
    chooseSubscriptionType: createUAEvent,
    activationComplete: createActivationCompleteEvent,
    trialRefused: createTrialRefusedEvent,
    geoBlocked: createGeoBlockedEvent,
    share: createShareEvent,
    quantity: createBuyGiftcardQuantityEvent,
    buyGiftcard: createBuyGiftcardEvent,
    redeemExistingCustomer: createRedeemExistingCustomerEvent,
    redeemCampaignCode: createRedeemCampaignCodeEvent
};

export const createDataLayerStrategy = (): TrackingStrategy => ({
    track: (trackingEvent: TrackingEvent) => {
        if (!isBrowser()) return;

        window.dataLayer = window.dataLayer || [];

        // Used for UA events
        const { event, ...data } = trackingEvent;
        const eventCreator = UAEventMap[event];
        eventCreator && push(eventCreator(data));

        // Push event as is for GA4
        if (!eventCreator && trackingEvent?.event) {
            push(trackingEvent);
        }
    }
});
