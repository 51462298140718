import { useSWRConfig } from 'swr';
import { api } from './api';
import { handleError } from './handleError';
import type { UseApiOptions } from './useApi';

export const FIXED_OPTIONS = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
} as const;

export const createSWRConfig = <Response>(
    config?: UseApiOptions<Response>
) => ({
    ...config,
    ...(config?.fixed ? FIXED_OPTIONS : {}),
    enabled: config?.enabled ?? true,
    addAsLiveQuery: config?.addAsLiveQuery ?? false
});

export type FetcherMethod =
    | 'get'
    | 'patch'
    | 'put'
    | 'delete'
    | 'post'
    | 'head';

export const fetcher = <T>(url: string, method: FetcherMethod = 'get') =>
    api[method]<T>(url, { headers: { 'Accept-Language': '' } })
        .then((res) => res.data)
        .catch((err) => {
            throw handleError(err);
        });

export const useInvalidateSWRCacheByPrefix = () => {
    const { cache, mutate } = useSWRConfig();

    /**
     * This function takes a string pattern and invalidates all cache keys that
     * match the pattern. This is useful when you want to invalidate all cache
     * keys that are related to a specific API URL.
     *
     * @example
     * ```tsx
     * const { invalidateCacheByPrefix } = useInvalidateSWRCacheByPrefix();
     *
     * invalidateCacheByPrefix('/api/hal'); // Runs `mutate` for all cache keys that start with '/api/hal'
     * ```
     */
    const invalidateCacheByPrefix = (prefix: string) => {
        if (!cache) {
            throw new Error(
                'useInvalidateSWRCacheByPrefix(): must be used within a SWRConfig provider'
            );
        }

        // Get all cache keys and filter those that match the prefix
        const keysToDelete = Array.from(cache.keys()).filter((key) =>
            key.startsWith(prefix)
        );

        // Invalidate each matching key and return promise that resolves when all are done.
        return Promise.all(keysToDelete.map((key) => mutate(key)));
    };

    return { invalidateCacheByPrefix };
};
