// @ts-strict-ignore
import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app';
import { Debug } from 'bb/app/debug';
import { TrackingContext } from 'bb/tracker/contexts';
import { type TrackingStrategy } from 'bb/tracker/types';

type AccountPageTracker = {
    addProfile(isKid: boolean): void;
    pauseProfile(): void;
    activateProfile(): void;
    changeSubscription(product: string, currentProduct: string): void;
    topUpPurchase(product: string): void;
    initiateTopUpPurchase(): void;
};

const debug = Debug('useAccountPageTracker');

const accountPageTracker = (
    context: string,
    tracker: TrackingStrategy
): AccountPageTracker => ({
    addProfile: (isKid): void => {
        const action = isKid ? 'add-kid' : 'add';
        tracker.track({ event: 'familyaccount', action });
        debug.info({ event: 'familyaccount', action });
    },
    pauseProfile: (): void => {
        tracker.track({ event: 'familyaccount', action: 'pause' });
        debug.info({ event: 'familyaccount', action: 'pause' });
    },
    activateProfile: (): void => {
        tracker.track({ event: 'familyaccount', action: 'activate' });
        debug.info({ event: 'familyaccount', action: 'activate' });
    },
    changeSubscription: (product, currentProduct): void => {
        tracker.track({
            event: 'subscription',
            action: 'change-subscription',
            toProduct: product,
            fromProduct: currentProduct
        });
    },
    topUpPurchase: (product): void => {
        tracker.track({ event: context, action: 'topup', product });
    },
    initiateTopUpPurchase: (): void => {
        tracker.track({
            event: context,
            action: 'topup',
            product: 'go to confirm purchase'
        });
    }
});

export const useAccountPageTracker = (): AccountPageTracker => {
    const { trackingStrategy } = useContext(AppContext);
    const { context } = useContext(TrackingContext);

    return useMemo(
        () => accountPageTracker(context, trackingStrategy),
        [context, trackingStrategy]
    );
};
