import React from 'react';
import classNames from 'classnames';
import { Text, type TranslationPrefix } from 'bb/i18n';
import { Box, type BoxProps } from '../Box';
import { Flex } from '../Flex';
import { useTranslationsFromDiscovery } from '../hooks/useTranslationsFromDiscovery';
import { ImageIcon, type SvgName } from '../Icons';
import css from './badge.module.scss';

export type BadgeProps = {
    translationKey: TranslationPrefix;
    icon?: SvgName;
    className?: BoxProps['className'];
};

export const Badge = (props: BadgeProps) => {
    const { translationKey, icon, className } = props;
    const text = useTranslationsFromDiscovery(translationKey);

    return (
        <Box
            bgColor="tertiary-purple"
            className={classNames(css.base, className)}
        >
            <Flex alignItems="center" wrap="noWrap" className={css.minHeight}>
                {icon && (
                    <Box bgColor="primary-purple" className={css.icon}>
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            className={css.minHeight}
                        >
                            <ImageIcon svg={icon} size="small" />
                        </Flex>
                    </Box>
                )}
                <Text
                    as="span"
                    variant="body3"
                    className={css.text}
                    aria-hidden
                    color="primary-black"
                >
                    {text}
                </Text>
            </Flex>
        </Box>
    );
};
