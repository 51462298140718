export { default as AppleIcon } from './apple-icon.svg';
export { default as ArrowDownIcon } from './arrowdown.svg';
export { default as AtIcon } from './at.svg';
export { default as AvatarIcon } from './avatar.svg';
export { default as BoxedArrowRightIcon } from './boxed-arrow-right.svg';
export { default as CheckIcon } from './check.svg';
export { default as ChevronDown } from './chevron-down.svg';
export { default as ChevronEnd } from './chevron-end.svg';
export { default as ChevronLeft } from './chevron-left.svg';
export { default as ChevronRight } from './chevron-right.svg';
export { default as ChevronStart } from './chevron-start.svg';
export { default as CircleArrowRight } from './circle-arrow-right.svg';
export { default as CreditCardIcon } from './creditcard.svg';
export { default as DiscountIcon } from './discount.svg';
export { default as Down } from './down.svg';
export { default as EmailIcon } from './email.svg';
export { default as ErrorIcon } from './error.svg';
export { default as ErrorIconFilled } from './error_filled.svg';
export { default as FeedbackIcon } from './feedback.svg';
export { default as GiftcardIcon } from './giftcard.svg';
export { default as GlassesIcon } from './glasses.svg';
export { default as HeadphonesIcon } from './headphones.svg';
export { default as GoogleIcon } from './google-icon.svg';
export { default as HashtagIcon } from './hashtag.svg';
export { default as IdealIcon } from './ideal.svg';
export { default as InfoIconOutlined } from './info.svg';
export { default as InformationIcon } from './information.svg';
export { default as KeyIcon } from './key.svg';
export { default as KlarnaIcon } from './klarna.svg';
export { default as ApplePayIcon } from './ApplePayIcon.svg';
export { default as Left } from './left.svg';
export { default as LockedIcon } from './locked.svg';
export { default as MagicWand } from './magic-wand.svg';
export { default as Menu } from './menu.svg';
export { default as MinusIcon } from './minus.svg';
export { default as OfferIcon } from './offer.svg';
export { default as PasswordIcon } from './password.svg';
export { default as PaypalIcon } from './paypal.svg';
export { default as PlusIcon } from './plus.svg';
export { default as ProfileBlue } from './Profiles/blue.svg';
export { default as ProfileBlueKids } from './Profiles/blueKids.svg';
export { default as ProfileGray } from './Profiles/gray.svg';
export { default as ProfileGreen } from './Profiles/green.svg';
export { default as ProfileGreenKids } from './Profiles/greenKids.svg';
export { default as ProfileInactive } from './Profiles/inactive.svg';
export { default as ProfileInactiveKids } from './Profiles/inactiveKids.svg';
export { default as ProfilePurple } from './Profiles/purple.svg';
export { default as ProfilePurpleKids } from './Profiles/purpleKids.svg';
export { default as ProfileRed } from './Profiles/red.svg';
export { default as ProfileRedKids } from './Profiles/redKids.svg';
export { default as ProfileYellow } from './Profiles/yellow.svg';
export { default as ProfileYellowKids } from './Profiles/yellowKids.svg';
export { default as RedeemGiftCardIcon } from './redeem-giftcard.svg';
export { default as Right } from './right.svg';
export { default as SuccessIcon } from './success.svg';
export { default as UnlockedIcon } from './unlocked.svg';
export { default as Up } from './up.svg';
export { default as UpcomingIcon } from './upcoming.svg';
export { default as UserIcon } from './user.svg';
export { default as WarningIcon } from './warning.svg';
export { default as SpinnerIcon } from './spinner.svg';
export { default as ConfettiIcon } from './confetti.svg';
export { default as EnvelopeIcon } from './envelope.svg';
