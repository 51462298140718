// @ts-strict-ignore
import React, { type FunctionComponent, type CSSProperties } from 'react';
import classnames from 'classnames';
import { type DataKey } from 'bb/ui/types';
import { type MarginSize, marginTopCss } from '../../Margin';
import alignItemsCss from '../alignItems.module.scss';
import flexWrapCss from '../flexWrap.module.scss';
import justifyContentCss from '../justifyContent.module.scss';
import {
    type JustifyContentType,
    type AlignItemsType,
    type FlexWrapType
} from '../types';
import gutterCss from './gutter.module.scss';
import css from './row.module.scss';

export type GutterSize = 'extraSmall' | 'small' | 'medium' | 'large';

type FlexDirection = 'row' | 'column';

export type RowProps = {
    justifyContent?: JustifyContentType;
    alignItems?: AlignItemsType;
    className?: string;
    marginTop?: MarginSize;
    flexWrap?: FlexWrapType;
    style?: CSSProperties;
    reverse?: boolean;
    dataKey?: DataKey;
    flexDirection?: FlexDirection;
    gutter?: GutterSize | [GutterSize] | [GutterSize, GutterSize];
    children?: React.ReactNode;
};

export const Row: FunctionComponent<RowProps> = ({
    justifyContent = 'flexStart',
    alignItems = 'flexStart',
    reverse = false,
    marginTop,
    className = '',
    dataKey,
    flexWrap = 'noWrap',
    style,
    gutter,
    flexDirection,
    children,
    ...restProps
}) => (
    <div
        className={classnames(css.base, className, {
            [marginTopCss[marginTop as string]]: Boolean(marginTop)
        })}
        {...restProps}
    >
        <div
            className={classnames(
                css.flex,
                css[flexDirection as string],
                gutterCss[`x-${Array.isArray(gutter) ? gutter[0] : gutter}`],
                gutterCss[`y-${Array.isArray(gutter) ? gutter[1] : gutter}`],
                {
                    [alignItemsCss[alignItems as string]]: Boolean(alignItems),
                    [justifyContentCss[justifyContent as string]]:
                        Boolean(justifyContent),
                    [flexWrapCss[flexWrap as string]]: flexWrap,
                    [css.reverse]: reverse
                }
            )}
            style={style}
            data-key={dataKey}
        >
            {children}
        </div>
    </div>
);
