import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from '../Box';
import { Gap, type GapProps } from '../Gap';
import css from './list.module.scss';

export type ListProps = {
    /**
     * The type of list style to use.
     */
    listStyle?: 'disc' | 'decimal';
    /**
     * The type of list to render.
     */
    as?: 'ul' | 'ol';
    /**
     * Whether to separate list items with a border.
     */
    borderSeparated?: boolean;
    className?: string;
} & Omit<GapProps, 'as'>;

export const List: FunctionComponent<ListProps> = ({
    as = 'ul',
    listStyle,
    borderSeparated,
    className,
    children,
    ...rest
}) => {
    return (
        <Gap
            className={classnames(
                css.list,
                className,
                listStyle && [css[listStyle as string]],
                borderSeparated && [css.borderSeparated]
            )}
            as={as}
            {...rest}
        >
            {children}
        </Gap>
    );
};

export type ListItemProps = Pick<
    BoxProps<'li'>,
    'padding' | 'fluid' | 'children'
> &
    React.HTMLAttributes<HTMLLIElement>;

export const ListItem = ({
    padding,
    fluid,
    children,
    ...rest
}: ListItemProps) => (
    <Box as="li" padding={padding} fluid={fluid} {...rest}>
        {children}
    </Box>
);
