import { type UnionOf } from '../common/types';

/**
 * Delocalized markets.
 */
export const SUB_MARKETS = [
    'bg',
    'hr',
    'cy',
    'ee',
    'gr',
    'hu',
    'ie',
    'lv',
    'lt',
    'lu',
    'mt',
    'pt',
    'ro',
    'sk',
    'si',
    'cz',
    'es',
    'it',
    'fr'
] as const;

export const CORE_MARKETS = [
    'se',
    'de',
    'fi',
    'uk',
    'pl',
    'dk',
    'ch',
    'at',
    'no',
    'nl',
    'be'
] as const;

export const ALL_LOCALES = [
    'da',
    'de',
    'de-AT',
    'de-CH',
    'en',
    'en-GB',
    'fi',
    'nb',
    'nl-BE',
    'nl-NL',
    'pl',
    'sv'
] as const;

export const ALL_MARKETS = [...SUB_MARKETS, ...CORE_MARKETS] as const;

export const DACH_MARKETS = ['de', 'at', 'ch'] as const;

export const isDachMarket = (market: Market) =>
    DACH_MARKETS.includes(market as DachMarket);

export const isSubMarket = (market: Market) => {
    return SUB_MARKETS.includes(market as SubMarket);
};

export const isCoreMarket = (market: Market) => {
    return CORE_MARKETS.includes(market as CoreMarket);
};

export const getDachMarkets = () => DACH_MARKETS;

export const MARKET_TO_CURRENCY = {
    cz: 'CZK',
    uk: 'GBP',
    se: 'SEK',
    ch: 'CHF',
    pl: 'PLN',
    dk: 'DKK',
    no: 'NOK'
};

export const getCurrencyByMarket = (market: string) =>
    MARKET_TO_CURRENCY[market as keyof typeof MARKET_TO_CURRENCY] || 'EUR';

/* CMS Keys */
export const LOCALE_TO_CMS_KEY = {
    en: 'eu',
    'en-GB': 'uk',
    sv: 'se',
    de: 'de',
    'de-AT': 'de',
    'de-CH': 'de',
    fi: 'fi',
    pl: 'pl',
    da: 'dk',
    nb: 'no',
    'nl-NL': 'nl',
    'nl-BE': 'be'
};

export const getCmsKeyByLocale = (locale: string) => {
    return LOCALE_TO_CMS_KEY[locale as keyof typeof LOCALE_TO_CMS_KEY] || 'eu';
};

const MARKET_TO_CMS_KEYS = {
    eu: 'eu',
    uk: 'uk',
    se: 'se',
    de: 'de',
    at: 'de',
    ch: 'de',
    fi: 'fi',
    pl: 'pl',
    dk: 'dk',
    no: 'no',
    nl: 'nl',
    be: 'be'
};

export const getCmsKeyByMarket = (market?: string) => {
    return (
        MARKET_TO_CMS_KEYS[market as keyof typeof MARKET_TO_CMS_KEYS] || 'eu'
    );
};

export const MARKET_TO_LOCALE_MAPPING = {
    uk: 'en-GB',
    se: 'sv',
    de: 'de',
    at: 'de-AT',
    ch: 'de-CH',
    fi: 'fi',
    pl: 'pl',
    dk: 'da',
    no: 'nb',
    nl: 'nl-NL',
    be: 'nl-BE'
} as const;

/**
 * Mapping from locale to full locale
 *
 * Used for sign in with apple and Adyen checkout.
 *
 * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
 * https://docs.adyen.com/online-payments/classic-integrations/checkout-sdks/web-sdk/customization/localization/
 */
export const LOCALE_TO_FULL_LOCALE_MAPPING = {
    'en-GB': 'en_GB',
    sv: 'sv_SE',
    de: 'de_DE',
    'de-AT': 'de_DE',
    'de-CH': 'de_DE',
    fi: 'fi_FI',
    pl: 'pl_PL',
    da: 'da_DK',
    nb: 'no_NO',
    'nl-NL': 'nl_NL',
    'nl-BE': 'nl_NL',
    es: 'en_GB',
    it: 'en_GB',
    fr: 'en_GB'
} as const;

export const getFullLocaleByLocale = (
    locale: string,
    config?: { divider: string }
) => {
    const fullLocale =
        LOCALE_TO_FULL_LOCALE_MAPPING[
            locale as keyof typeof LOCALE_TO_FULL_LOCALE_MAPPING
        ] || 'en_US';

    if (config?.divider) {
        return fullLocale.replace('_', config.divider);
    }
    return fullLocale;
};

export const getLocaleByMarket = (market: string) => {
    return (
        MARKET_TO_LOCALE_MAPPING[
            market as keyof typeof MARKET_TO_LOCALE_MAPPING
        ] || 'en'
    );
};

export type SubMarket = UnionOf<typeof SUB_MARKETS>;

export type Market = UnionOf<typeof ALL_MARKETS>;

export type Locale = UnionOf<typeof ALL_LOCALES>;

export type CoreMarket = UnionOf<typeof CORE_MARKETS>;

export type DachMarket = UnionOf<typeof DACH_MARKETS>;
