import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app/AppContext';
import { Debug } from 'bb/app/debug';
import { TrackingContext } from 'bb/tracker/contexts';
import type { TrackingStrategy } from 'bb/tracker/types';

type RegistrationTracker = {
    downloadApp(platform: string): void;
    scrollToCta(button: string, location: string): void;
};

const debug = Debug('useRegistrationTracker');

const registrationTracker = (
    type: string,
    tracker?: TrackingStrategy
): RegistrationTracker => ({
    downloadApp: (platform): void => {
        tracker?.track({
            event: 'downloadApp',
            action: 'downloadApp',
            label: platform,
            category: type
        });
        debug.info(`${platform} - download app`);
    },
    scrollToCta: (button, location) => {
        tracker?.track({
            event: 'cta_click',
            button,
            location
        });
    }
});

export const useRegistrationTracker = (): RegistrationTracker => {
    const { trackingStrategy } = useContext(AppContext);
    const { context } = useContext(TrackingContext);
    return useMemo(
        () => registrationTracker(context, trackingStrategy),
        [context, trackingStrategy]
    );
};
