import React from 'react';
import classNames from 'classnames';
import { type Router, useRouter } from 'bb/app/router';
import { useIsClient } from 'bb/common/hooks';
import { useTranslation } from 'bb/i18n';
import { Gap, Typography, type TypographyProps } from 'bb/ui';
import { Link } from '../Link';
import css from './breadcrumbs.module.scss';
import {
    type Routes,
    type BreadcrumbsProps,
    type MakeBreadcrumbsQuery
} from './Breadcrumbs.types';
import { isDynamicSegment, useBreadcrumbs } from './helpers';

export const Breadcrumbs = <
    TRoute extends keyof Routes,
    TQuery extends MakeBreadcrumbsQuery<TRoute> = MakeBreadcrumbsQuery<TRoute>
>(
    props: BreadcrumbsProps<TRoute>
) => {
    const { t, ready } = useTranslation(['breadcrumbs']);
    const {
        className,
        route,
        query = {},
        typographyProps = {},
        ref,
        ...restProps
    } = props;
    const { routes } = useRouter();
    const { getBreadcrumbs } = useBreadcrumbs();
    const isClient = useIsClient();

    const breadcrumbs = getBreadcrumbs(route);

    /**
     * Constructs the query object for the dynamic parts of the breadcrumbs.
     */
    const extractDynamicQuery = (
        crumb: ReturnType<typeof getBreadcrumbs>[number]
    ) =>
        crumb.href.split('/').reduce<TQuery>((obj, item) => {
            if (isDynamicSegment(item)) {
                const key = item.substring(1, item.length - 1) as keyof TQuery;

                // eslint-disable-next-line no-param-reassign
                if (key !== 'market') obj[key] = (query as TQuery)[key];
            }

            return obj;
        }, {} as TQuery);

    /**
     * Constructs the query object that is passed to the Link component.
     */
    const makeLinkQuery = (crumbQuery: TQuery) => {
        const dynamicQueryEntries = Object.entries(crumbQuery) as [
            keyof TQuery,
            TQuery[keyof TQuery]
        ][];

        return dynamicQueryEntries.reduce<Router['router']['query']>(
            (acc, [key, { value }]) => ({ ...acc, [key]: value }),
            {} as Router['router']['query']
        );
    };

    const commonTypographyProps: TypographyProps<'span'> = {
        ...typographyProps,
        variant: 'body2',
        as: 'span',
        className: classNames(css.text, typographyProps.className)
    };

    return (
        <Gap
            {...restProps}
            ref={ref}
            className={classNames(css.root, className)}
            direction="row"
            spacing={2}
        >
            {
                /**
                 * Default display value is the translation of the key.
                 * This is used for most routes (static breadcrumbs).
                 * Only render the breadcrumbs if the translation is ready
                 * and we are on the client. This is because we don't wanna
                 * load the breadcrumbs translations on the server.
                 */
                !ready || !isClient
                    ? null
                    : breadcrumbs.map((crumb, index) => {
                          /**
                           * Default display value is the translation of the key.
                           * This is used for most routes (static breadcrumbs).
                           */
                          let displayValue: string | number = t(
                              crumb.transKey(crumb.key)
                          );

                          const dynamicQuery = extractDynamicQuery(crumb);
                          const linkQuery = makeLinkQuery(dynamicQuery);

                          /**
                           * If the last segment of the href is a dynamic slug not equal to [market], it is a dynamic
                           * breadcrumb. Prefer to interpolate the displayValue and fallback
                           * on the value if it's not present.
                           */
                          const lastSegment = crumb.href.split('/').at(-1);
                          if (
                              lastSegment &&
                              lastSegment !== '[market]' &&
                              isDynamicSegment(lastSegment)
                          ) {
                              const dynamicQueryValue =
                                  dynamicQuery[
                                      lastSegment.substring(
                                          1,
                                          lastSegment.length - 1
                                      ) as keyof TQuery
                                  ];

                              displayValue = String(
                                  dynamicQueryValue.displayValue ??
                                      dynamicQueryValue.value ??
                                      displayValue
                              );
                          }

                          const isActive = index === breadcrumbs.length - 1;
                          const innerElement = (
                              <Typography
                                  {...commonTypographyProps}
                                  data-testid="breadcrumb-element"
                                  bold={isActive}
                              >
                                  {displayValue}
                              </Typography>
                          );

                          return (
                              <React.Fragment key={crumb.name}>
                                  {isActive ? (
                                      innerElement
                                  ) : (
                                      <Link
                                          className={css.crumb}
                                          route={routes[crumb.key]}
                                          query={linkQuery}
                                          color={typographyProps.color}
                                      >
                                          {innerElement}
                                      </Link>
                                  )}
                                  {index < breadcrumbs.length - 1 ? (
                                      <Typography
                                          aria-hidden
                                          {...commonTypographyProps}
                                      >
                                          {' / '}
                                      </Typography>
                                  ) : (
                                      ''
                                  )}
                              </React.Fragment>
                          );
                      })
            }
        </Gap>
    );
};
