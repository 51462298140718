import { type ForwardedRef, useEffect, useRef } from 'react';
import {
    type FieldValues,
    type UseFormProps as HookFormUseFormProps,
    useForm as useHookForm,
    type FieldErrors,
    type FieldPath,
    type UseFormGetFieldState,
    type UseFormRegisterReturn,
    type RegisterOptions
} from 'react-hook-form';
import { type ErrorResponse } from 'bb/api/browser/types';
import { assignRef } from 'bb/utils';

export type SubmitErrorHandler<TFieldValues extends FieldValues> = (
    errors: FieldErrors<TFieldValues>,
    form: UseFormReturn<TFieldValues>,
    event?: React.BaseSyntheticEvent
) => unknown | Promise<unknown>;

export type SubmitHandler<
    TFieldValues extends FieldValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TReturnType = unknown
> = (
    data: TFieldValues,
    form: UseFormReturn<TFieldValues>,
    event?: React.BaseSyntheticEvent
) => TReturnType | Promise<TReturnType>;

export type UseFormFieldListenerHandler<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    ListenerProperty extends 'onChange' | 'onBlur' = 'onChange' | 'onBlur'
> = (
    event: Parameters<
        Exclude<
            RegisterOptions<TFieldValues, TFieldName>[ListenerProperty],
            undefined
        >
    >[0],
    getFieldState: () => ReturnType<UseFormGetFieldState<TFieldValues>> &
        (ListenerProperty extends 'onBlur'
            ? {
                  /**
                   * The current validity of the field. This is added by ourselves
                   * using trigger. We need this because the error coming from
                   * react-hook-form when using onTouched mode happens to late
                   * for us to be able to use it for tracking purposes.
                   */
                  isValid: boolean;
              }
            : // eslint-disable-next-line @typescript-eslint/no-empty-object-type
              {})
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

export type UseFormFieldListeners<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Record<
    TFieldName,
    {
        onChange?: UseFormFieldListenerHandler<
            TFieldValues,
            TFieldName,
            'onChange'
        >;
        onBlur?: UseFormFieldListenerHandler<
            TFieldValues,
            TFieldName,
            'onBlur'
        >;
    }
>;

export type UseFormRegister<TFieldValues extends FieldValues> = <
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    TElement extends HTMLElement = HTMLElement
>(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName> & {
        /**
         * Ref to the element. This is useful for when we want to
         * do something custom with the element, like focusing.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref?: ForwardedRef<TElement>;
    }
) => UseFormRegisterReturn<TFieldName>;

export type UseFormProps<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    TElement extends HTMLElement = HTMLElement
> = HookFormUseFormProps<TFieldValues> & {
    /**
     * If true, the form will be reset on success.
     *
     * @defaultValue false
     */
    resetOnSuccess?: boolean;
    /**
     * If true, the form will be reset on error.
     *
     * @defaultValue false
     */
    resetOnError?: boolean;
    /**
     * Object where we can pass onChange/onBLur listeners from the parent
     * component. This is useful for when we want to do something
     * custom with the form values, like tracking.
     *
     * The first argument that is passed to the listener is the
     * native event. The second argument is the field state.
     *
     * In addition to passing each individual field name, we can also
     * pass a '__ALL__' key to listen to all fields. If both are passed,
     * the '__ALL__' key will be ignored for that field.
     *
     * @defaultValue {}
     */
    fieldListeners?: Partial<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        UseFormFieldListeners<TFieldValues & { __ALL__: any }>
    >;
    /**
     * Object where we can pass refs from the parent component.
     *
     * @example
     * ```tsx
     * const inputRef = useRef<HTMLInputElement | null>(null);
     *
     * return <FormComponent fieldRefs={{ inputName: inputRef }} />
     * ```
     *
     * @defaultValue {}
     */
    fieldRefs?: Partial<Record<TFieldName, ForwardedRef<TElement>>>;
};

export type UseFormHandleSubmit<
    TFieldValues extends FieldValues,
    TTransformedValues extends FieldValues | undefined = undefined
> = (
    onValid: TTransformedValues extends undefined
        ? SubmitHandler<TFieldValues>
        : TTransformedValues extends FieldValues
          ? SubmitHandler<TTransformedValues>
          : never,
    onInvalid?: SubmitErrorHandler<TFieldValues>
) => (e?: React.BaseSyntheticEvent) => Promise<void>;

export type UseFormReturn<TFieldValues extends FieldValues> = ReturnType<
    typeof useForm<TFieldValues>
>;

export type UseHookFormComponentProps<TFieldValues extends FieldValues> =
    UseFormProps<TFieldValues> & {
        disabled?: boolean;
        error?: ErrorResponse | Error | undefined | null;
        formDescriptionId?: string;
        isLoading?: boolean;
        onError?: SubmitErrorHandler<TFieldValues>;
        onSubmit: SubmitHandler<TFieldValues>;
    };

/**
 * Wrapper around useForm from react-hook-form so we
 * can have our own defaults.
 */
export const useForm = <TFieldValues extends FieldValues>({
    fieldListeners = {},
    fieldRefs = {},
    ...restProps
}: UseFormProps<TFieldValues>) => {
    const {
        mode = 'onTouched',
        resetOnError = false,
        resetOnSuccess = false,
        ...restUseHookFormProps
    } = restProps;

    const hookForm = useHookForm({
        mode,
        ...restUseHookFormProps
    });

    const {
        handleSubmit: defaultHandleSubmit,
        register: defaultRegister,
        formState: defaultFormState,
        ...restForm
    } = hookForm;

    const lastSubmittedValues = useRef<TFieldValues | undefined>(undefined);

    const { reset, getFieldState } = hookForm;

    const formState = Object.assign(defaultFormState, {
        /**
         * Similar to `isDirty`, but only takes dirty fields into account.
         */
        hasDirtyFields: Object.keys(defaultFormState.dirtyFields).length > 0,
        /**
         * The values from the last submit.
         */
        lastSubmittedValues: lastSubmittedValues.current
    });

    const { isSubmitSuccessful, isSubmitted } = formState;

    useEffect(() => {
        if (isSubmitSuccessful && resetOnSuccess) {
            reset();
        }
    }, [isSubmitSuccessful, resetOnSuccess, reset]);

    useEffect(() => {
        if (isSubmitted && resetOnError && !isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitted, isSubmitSuccessful, resetOnError, reset]);

    const register: UseFormRegister<TFieldValues> = (...args) => {
        const [name, options] = args;

        const passedNamedFieldListener = (
            fieldListeners as UseFormFieldListeners<TFieldValues>
        )[name];
        const passedAllFieldListener = (
            fieldListeners as UseFormFieldListeners<TFieldValues>
        )['__ALL__' as typeof name];

        if (passedNamedFieldListener || passedAllFieldListener) {
            const { onChange, onBlur, ...restOptions } = options ?? {};

            const defaultRegisterResult = defaultRegister(name, {
                ...restOptions,
                onChange: async (event) => {
                    passedNamedFieldListener?.onChange?.(event, () =>
                        getFieldState(name)
                    );
                    passedAllFieldListener?.onChange?.(event, () =>
                        getFieldState(name)
                    );
                    onChange?.(event);
                },
                onBlur: async (event) => {
                    /**
                     * The validation is triggered here so we can get
                     * a fresh boolean value for isValid. The default
                     * behaviour from react-hook-form is to trigger
                     * after blur. This is not supported in onChange
                     * becauase it would result in very many validation
                     * triggers which ultimately hurts performance.
                     */
                    const isValid = await hookForm.trigger(name);

                    passedNamedFieldListener?.onBlur?.(event, () => ({
                        ...getFieldState(name),
                        isValid
                    }));
                    passedAllFieldListener?.onBlur?.(event, () => ({
                        ...getFieldState(name),
                        isValid
                    }));
                    onBlur?.(event);
                }
            });

            return {
                ...defaultRegisterResult,
                ref: (intance) => {
                    assignRef(fieldRefs[name], intance);
                    assignRef(options?.ref, intance);
                    defaultRegisterResult.ref(intance);
                }
            };
        }

        const defaultRegisterResult = defaultRegister(name, options);

        return {
            ...defaultRegisterResult,
            ref: (intance) => {
                assignRef(fieldRefs[name], intance);
                assignRef(options?.ref, intance);
                defaultRegisterResult.ref(intance);
            }
        };
    };

    /**
     * Like handleSubmit from react-hook-form, but with form instance
     * being passed as the second argument. This allows us to do custom
     * things like reset the form on success more easily if any conditionals
     * are needed.
     */
    const handleSubmit: UseFormHandleSubmit<TFieldValues> = (
        passedOnValid,
        passedOnInvalid
    ) => {
        const form = Object.assign(restForm, {
            handleSubmit,
            register,
            formState
        });

        const onValid: Parameters<typeof defaultHandleSubmit>[0] = async (
            values,
            event
        ) => {
            lastSubmittedValues.current = values;

            return passedOnValid(values, form, event);
        };

        const onInvalid: Parameters<typeof defaultHandleSubmit>[1] = async (
            errors,
            event
        ) => passedOnInvalid?.(errors, form, event);

        return defaultHandleSubmit(onValid, onInvalid);
    };

    /**
     * react-hook-form uses Proxy to return the form instance, so we need to
     * use Object.assign to make sure we don't break that.
     */
    return Object.assign(restForm, { handleSubmit, register, formState });
};
